import React, { useEffect } from 'react';

import { useFacebookLandingText } from '@src/hooks/directusHooks/useFacebookLandingText';
import SocialNetworkV2FreePage from '@src/components/social-network-free-page/social-network-v2';
import couponCodeService from '@src/utils/couponCodeService';

const lp3_30_3 = () => {
  const { setOffer } = couponCodeService();
  const { landing, home } = useFacebookLandingText();

  const items = [
    {
      title: 'Take your health quiz',
      description: 'Tell us about your diet & lifestyle in just 5 minutes.',
    },
    {
      title: 'Get your personalised pack',
      description: 'We’ll create your pack based on your answers.',
    },
    {
      title: 'Get 30% off your first 3 months',
      description: 'Get your personalised vitamins. Change or cancel your plan anytime.',
    },
  ];

  const text = {
    ...landing,
    ...home,
    hero_text: 'Choose vitamins personalised to you and get ',
    hero_focus: '30% off for 3 months today',
    hero_focus_price: null,
    how_it_work_header: 'How it works',
    how_it_works_items_list: items,
    hero_price_element: '<b><s>£39.95</s> <span>£27.96/mo</span> for 3 months</b>',
    sticky_banner_text: 'Take Quiz & get 30% off 3 months',
  };

  useEffect(() => {
    setOffer('4pp', '30off');
  }, []);

  return <SocialNetworkV2FreePage text={text} noindex={true} />;
};

export default lp3_30_3;
